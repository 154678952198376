.navbar {
  background-color: #ffffff;
  padding: 15px 64px;
  border-bottom: 1px solid var(--color-black-10);
}

.navbar-brand {
  width: 208px;
  height: 108px;
}

/*Begin Tablet, 729-1024px*/
.navbar-brand > img {
  width: 100%;
  height: 100%;
}

.nav-item > a {
  color: rgb(var(--color-black));
  font-size: var(--paragraph-tablet-size);
  font-family: var(--paragraph-font);
  line-height: var(--paragraph-tablet-height);
  padding: 0 14px !important;
}
/*End Tablet, 729-1024px*/

.nav-item > a:hover {
  color: var(--color-black-50);
}

.nav-item > a:active {
  color: var(--color-black-25);
}

.current {
  font-family: var(--paragraph-bold) !important;
}

.nav-menu {
  color: var(--color-black-75);
  font-size: 44px;
  border: none;
}

.nav-menu:hover {
  color: rgb(var(--color-black));
}

.nav-menu:active {
  opacity: 0.5;
}

.nav-menu:focus {
  outline: none;
  box-shadow: none;
}

/*Tablet, 729-1025px*/
@media (max-width: 1024px) {
  .navbar-brand {
    width: 128px;
    height: 60px;
  }

  .navbar {
    padding: 8px 64px;
  }
}

/*PC, >= 1025px*/
@media (min-width: 1025px) {
  .navbar-brand {
    width: 150px;
    height: 69px;
  }

  .nav-item > a {
    font-size: var(--paragraph-size);
    font-family: var(--paragraph-font);
    line-height: var(--paragraph-height);
    padding: 0 16px !important;
  }
}

/*MOBILE, 0-728px*/
@media (max-width: 728px) {
  .navbar {
    padding: 15px 32px;
  }

  .nav-item > a {
    font-size: var(--paragraph-size);
    line-height: var(--paragraph-height);
  }

  .navbar-brand {
    width: 40px;
    height: 64px;
  }

  .navbar-brand > img {
    content: url('./../../icon.png');
  }
}
