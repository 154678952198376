.contact-buttons button {
  background-image: none;
  background-color: var(--color-primary);
}

.location a:hover {
  text-decoration: underline;
}

.location a:active {
  opacity: 0.75;
}

/*Begins Tablet, 729-1024px*/
.contact-info {
  padding: 64px 128px 96px;
}

.location {
  margin-bottom: 96px;
}

.location iframe {
  width: 100%;
  height: 240px;
  margin-bottom: 32px;
  box-shadow: 0px 100px 80px rgba(29, 39, 38, 0.07),
    0px 41.7776px 33.4221px rgba(29, 39, 38, 0.0503198),
    0px 22.3363px 17.869px rgba(29, 39, 38, 0.0417275),
    0px 12.5216px 10.0172px rgba(29, 39, 38, 0.035),
    0px 6.6501px 5.32008px rgba(29, 39, 38, 0.0282725),
    0px 2.76726px 2.21381px rgba(29, 39, 38, 0.0196802);
}

.location a,
.location p {
  text-decoration: none;
  color: var(--color-black-75);
  font-family: var(--paragraph-font);
  font-size: var(--paragraph-tablet-size);
  line-height: var(--paragraph-tablet-height);
}

.location-link p {
  margin-bottom: 32px;
}

.location h3,
.contact-buttons h3 {
  color: rgb(var(--color-black));
  font-family: var(--h3-font-bold);
  font-size: var(--h3-tablet-size);
  line-height: var(--h3-tablet-height);
  margin-bottom: 56px;
}

.contact-buttons .row:last-child {
  margin-top: 28px;
}

.location h5,
.contact-buttons h5 {
  color: rgb(var(--color-black));
  font-family: var(--h5-font-bold);
  font-size: var(--h5-tablet-size);
  line-height: var(--h5-tablet-height);
  margin-bottom: 28px;
}
/*Ends Tablet, 729-1024px*/

/*PC, >= 1025px*/
@media (min-width: 1025px) {
  .contact-info {
    padding: 64px 256px 128px;
  }

  .location {
    margin-bottom: 128px;
  }

  .location iframe {
    height: 300px;
    margin-bottom: 56px;
  }

  .location-link p {
    margin-bottom: 56px;
  }

  .location a,
  .location p {
    font-size: var(--paragraph-size);
    line-height: var(--paragraph-height);
  }

  .location h3,
  .contact-buttons h3 {
    font-size: var(--h3-size);
    line-height: var(--h3-height);
    margin-bottom: 64px;
  }

  .location h5,
  .contact-buttons h5 {
    font-size: var(--h5-size);
    line-height: var(--h5-height);
    margin-bottom: 32px;
  }

  .contact-buttons .row:last-child {
    margin-top: 32px;
  }
}

/*MOBILE, 0-728px*/
@media (max-width: 728px) {
  .contact-info {
    padding: 64px 64px 96px;
  }

  .location iframe {
    height: 180px;
  }

  .location a,
  .location p {
    font-size: var(--paragraph-size);
    line-height: var(--paragraph-height);
  }

  .location h3,
  .contact-buttons h3 {
    font-size: var(--h3-phone-size);
    line-height: var(--h3-phone-height);
    margin-bottom: 48px;
  }

  .location h5,
  .contact-buttons h5 {
    font-size: var(--h5-phone-size);
    line-height: var(--h5-phone-height);
  }
}
