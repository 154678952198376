/*Begin Tablet, 729-1024px*/
.standards {
  background-color: var(--color-black-10);
  padding: 96px 128px;
}

.standards h1 {
  color: var(--color-primary);
  font-family: var(--h1-font-bold);
  font-size: var(--h1-tablet-size);
  line-height: var(--h1-tablet-height);
  margin-bottom: 18px;
  text-shadow: 0px 100px 80px rgba(29, 39, 38, 0.07),
    0px 41.7776px 33.4221px rgba(29, 39, 38, 0.0503198),
    0px 22.3363px 17.869px rgba(29, 39, 38, 0.0417275),
    0px 12.5216px 10.0172px rgba(29, 39, 38, 0.035),
    0px 6.6501px 5.32008px rgba(29, 39, 38, 0.0282725),
    0px 2.76726px 2.21381px rgba(29, 39, 38, 0.0196802);
}
/*Ends Tablet, 729-1024px*/

/*PC, >= 1025px*/
@media (min-width: 1025px) {
  .standards {
    padding: 128px 256px;
  }

  .standards h1 {
    font-size: var(--h1-size);
    line-height: var(--h1-height);
  }
}

/*MOBILE, 0-728px*/
@media (max-width: 728px) {
  .standards {
    padding: 96px 64px;
  }

  .standards h1 {
    font-size: var(--h1-phone-size);
    line-height: var(--h1-phone-height);
  }
}
