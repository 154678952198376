/*Begin Mobile&Tablet, 0-1024px*/
.main-button {
  background-image: var(--color-brand-gradient);
  color: var(--color-white);
  font-family: var(--h6-font-bold);
  font-size: var(--h6-tablet-size);
  line-height: var(--h6-phone-height);
  padding: 18px 36px;
  width: auto;
  border: none;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
}

.main-link::before {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  top: 100%;
  left: 0;
  background: var(--color-brand-gradient);
}

.main-link {
  background-image: var(--color-brand-gradient);
  background-color: rgb(var(--color-secondary));
  background-size: 100%;
  font-family: var(--paragraph-bold);
  font-size: var(--paragraph-tablet-size);
  line-height: var(--paragraph-tablet-height);
  padding: 0;
  width: auto;
  border: none;
  position: relative;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
/*Ends Mobile&Tablet, 0-1024px*/

.button-hover {
  background-image: none;
  background-color: var(--color-primary);
  color: var(--color-white-75);
}

.button-active {
  background-image: none;
  background-color: var(--color-secondary);
  color: var(--color-black-50);
}

.link-hover {
  opacity: 0.75;
}

.link-active {
  opacity: 0.5;
}

/*PC, >= 1025px*/
@media (min-width: 1025px) {
  .main-button {
    font-size: var(--h6-size);
    line-height: var(--h6-height);
    padding: 20px 40px;
  }

  .main-link {
    font-size: var(--paragraph-size);
    line-height: var(--paragraph-height);
  }
}

/*MOBILE, 0-728px*/
@media (max-width: 728px) {
  .main-link {
    font-size: var(--paragraph-size);
    line-height: var(--paragraph-height);
  }
}
