body,
html {
  margin: 0;
  padding: 0;
}

html {
  --color-primary: #2c645e;
  --color-secondary: #38a194;
  --color-brand-gradient: linear-gradient(0.25turn, #38a194, #2c645e);
  --color-black: 29 39 38;
  --color-black-75: rgba(29, 39, 38, 0.75);
  --color-black-50: rgba(29, 39, 38, 0.5);
  --color-black-25: rgba(29, 39, 38, 0.25);
  --color-black-10: rgba(29, 39, 38, 0.1);
  --color-black-05: rgba(29, 39, 38, 0.05);
  --color-white: #f6fffe;
  --color-white-75: rgba(246, 255, 254, 0.75);

  --paragraph-size: 21px;
  --paragraph-height: 30px;
  --paragraph-font: Lato-Regular, sans-serif;
  --paragraph-bold: Lato-Bold, sans-serif;
  --paragraph-tablet-size: 18px;
  --paragraph-tablet-height: 28px;

  --h1-size: 119px;
  --h1-height: 142px;
  --h1-font-bold: Jost-Bold, sans-serif;
  --h1-tablet-size: 100px;
  --h1-tablet-height: 120px;
  --h1-phone-size: 54px;
  --h1-phone-height: 68px;

  --h2-size: 84px;
  --h2-height: 102px;
  --h2-font-bold: Jost-Bold, sans-serif;
  --h2-tablet-size: 72px;
  --h2-tablet-height: 88px;
  --h2-phone-size: 44px;
  --h2-phone-height: 58px;

  --h3-size: 60px;
  --h3-height: 72px;
  --h3-font-light: Jost-Light, sans-serif;
  --h3-font-bold: Jost-Bold, sans-serif;
  --h3-tablet-size: 50px;
  --h3-tablet-height: 60px;
  --h3-phone-size: 34px;
  --h3-phone-height: 46px;

  --h4-size: 42px;
  --h4-height: 52px;
  --h4-font-bold: Jost-Bold, sans-serif;
  --h4-tablet-size: 36px;
  --h4-tablet-height: 42px;
  --h4-phone-size: 26px;
  --h4-phone-height: 36px;

  --h5-size: 30px;
  --h5-height: 40px;
  --h5-font-bold: Jost-Bold, sans-serif;
  --h5-tablet-size: 26px;
  --h5-tablet-height: 34px;
  --h5-phone-size: 22px;
  --h5-phone-height: 30px;

  --h6-size: 21px;
  --h6-height: 28px;
  --h6-font-bold: Jost-Bold, sans-serif;
  --h6-phone-size: 18px;
  --h6-phone-height: 24px;

  --laptop-small-size: 14px;
  --laptop-small-height: 16px;
  --laptop-small-regular: Lato-Regular, sans-serif;
}

@font-face {
  font-family: Lato-Regular;
  src: url(./resources/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: Lato-Bold;
  src: url(./resources/fonts/Lato-Bold.ttf);
}

@font-face {
  font-family: Jost-Bold;
  src: url(./resources/fonts/Jost-Bold.ttf);
}

@font-face {
  font-family: Jost-Light;
  src: url(./resources/fonts/Jost-Light.ttf);
}
