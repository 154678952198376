/*Begin Tablet, 729-1024px*/
.history h3,
.mision-vision h3 {
  color: rgb(var(--color-black));
  font-family: var(--h3-font-bold);
  font-size: var(--h3-tablet-size);
  line-height: var(--h3-tablet-height);
  margin-bottom: 56px;
}

.history p,
.mision-vision p {
  color: var(--color-black-75);
  font-family: var(--paragraph-font);
  font-size: var(--paragraph-tablet-size);
  line-height: var(--paragraph-tablet-height);
}
/*Ends Tablet, 729-1024px*/

/*PC, >= 1025px*/
@media (min-width: 1025px) {
  .history h3,
  .mision-vision h3 {
    font-size: var(--h3-size);
    line-height: var(--h3-height);
    margin-bottom: 64px;
  }

  .history p,
  .mision-vision p {
    font-size: var(--paragraph-size);
    line-height: var(--paragraph-height);
  }
}

/*MOBILE, 0-728px*/
@media (max-width: 728px) {
  .history h3,
  .mision-vision h3 {
    font-size: var(--h3-phone-size);
    line-height: var(--h3-phone-height);
    margin-bottom: 48px;
  }

  .history p,
  .mision-vision p {
    font-size: var(--paragraph-size);
    line-height: var(--paragraph-height);
  }
}
